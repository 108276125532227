// App.js
import "./App.css";
import { ethers } from "ethers";
import { useForm } from "react-hook-form";
import abi from "./utils/bbsPortal.json";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faWallet, faRightToBracket, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";

const App = () => {

  const [currentAccount, setCurrentAccount] = useState("");
  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const { register, formState: { errors }, handleSubmit } = useForm();

  console.log("currentAccount: ", currentAccount);

  /*
  * デプロイされたコントラクトのアドレスを保持する変数を作成
  */
  const contractAddress = "0x90521581756B1690A13C834A916C0b85DD8D273b";
  /*
   * ABIの内容を参照する変数を作成
   */
  const contractABI = abi.abi;

  /*
  投稿一覧を取得
  orderBy 0:日付順
          1:お気入り順
  */
  const getAllPosts = async (orderBy = 0) => {
    const { ethereum } = window;

    try {
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const bbsPortalContract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        );
        const posts = await bbsPortalContract.getAllPosts();
        const likes = await bbsPortalContract.getPersonalLikes();
        const postsCleaned = posts.map((post) => {

          let isLiked = false;
          let likeId = -1;

          if (likes.length > 0) {
            likes
              .slice(0)
              .filter(like => like.postId.toNumber() === post.postId.toNumber())
              .forEach((like) => {
                isLiked = true;
                likeId = like.likeId;
              });
          }

          return {
            postId: post.postId,
            address: post.postby,
            message: post.message,
            likeNum: post.likeNum,
            isLiked: isLiked,
            likeId: likeId,
            timestamp: new Date(post.timestamp * 1000),
          };
        });

        if (orderBy === 1) {
          postsCleaned.sort(function (a, b) {
            return (a.likeNum > b.likeNum) ? 1 : -1;
          });
        }
        setAllPosts(postsCleaned);
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * `emit`されたイベントをフロントエンドに反映させる
   */
  useEffect(() => {
    let bbsPortalContract;
    const onNewPost = (postId, from, message, likeNum, timestamp) => {
      console.log("NewPost", postId, from, message, likeNum, timestamp);
      setAllPosts((prevState) => [
        ...prevState,
        {
          postId: postId,
          address: from,
          likeNum: likeNum,
          message: message,
          timestamp: new Date(timestamp * 1000),
        },
      ]);
    };

    /* NewPostイベントがコントラクトから発信されたときに、情報をを受け取ります */
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      bbsPortalContract = new ethers.Contract(
        contractAddress,
        contractABI,
        signer
      );
      bbsPortalContract.on("NewPost", onNewPost);
    }
    /*メモリリークを防ぐために、NewPostのイベントを解除します*/
    return () => {
      if (bbsPortalContract) {
        bbsPortalContract.off("NewPost", onNewPost);
      }
    };
  }, []);

  /*
  メッセージを投稿
  */
  const postContent = async () => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        setIsLoading(true);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const bbsPortalContract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        );
        const postTxn = await bbsPortalContract.postContent(
          message,
          {
            gasLimit: 300000,
          }
        );
        console.log("Mining...", postTxn.hash);
        await postTxn.wait();
        console.log("Mined -- ", postTxn.hash);
        setIsLoading(false);
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*
  メッセージにいいねをつける
  */
  const postLike = async (postId) => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        setIsLoading(true);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const bbsPortalContract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        );
        const likeTxn = await bbsPortalContract.postLike(postId, {
          gasLimit: 300000,
        });
        console.log("Mining...", likeTxn.hash);
        await likeTxn.wait();
        console.log("Mined -- ", likeTxn.hash);
        setIsLoading(false);
        getAllPosts();
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*
  メッセージのいいねを外す
  */
  const deleteLike = async (likeId) => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        setIsLoading(true);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const bbsPortalContract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        );
        const likeTxn = await bbsPortalContract.deleteLike(likeId, {
          gasLimit: 300000,
        });
        console.log("Mining...", likeTxn.hash);
        await likeTxn.wait();
        console.log("Mined -- ", likeTxn.hash);
        setIsLoading(false);
        getAllPosts();
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*
  メッセージ投稿者にETHを送る
  */
  const sendGift = async (_to) => {
    console.log("_to:" + _to);
    console.log("amount:" + amount);
    const { ethereum } = window;
    try {
      if (ethereum) {
        setIsLoading(true);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const bbsPortalContract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        );

        let sendAmount = ethers.utils.parseEther(amount);
        await bbsPortalContract.sendGift(
          _to,
          {
            gasLimit: 300000,
            value: sendAmount
          }
        );
        setIsLoading(false);
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*
   * window.ethereumにアクセスできることを確認します。
   */
  const checkIfWalletIsConnected = async () => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        console.log("Make sure you have MetaMask!");
        return;
      } else {
        console.log("We have the ethereum object", ethereum);
      }
      const accounts = await ethereum.request({ method: "eth_accounts" });
      if (accounts.length !== 0) {
        const account = accounts[0];
        console.log("Found an authorized account:", account);
        setCurrentAccount(account);
      } else {
        console.log("No authorized account found");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*
   * connectWalletメソッドを実装
   */
  const connectWallet = async () => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log("Connected: ", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkIfWalletIsConnected();
    getAllPosts();
  }, []);

  return (
    <div className="mainContainer">
      <div className="dataContainer">
        <div className="header">
          <span role="img" aria-label="hand-wave" className="headerImage">
            👋
          </span>{" "}
          <h1 className="headerTitle">ETH BBS!</h1>
          <div className="headerMenu">
            {!currentAccount && (
              <span className="connectWalletButton" onClick={connectWallet}>
                <FontAwesomeIcon icon={faRightToBracket} />
                Connect Wallet
              </span>
            )}
            {currentAccount && (
              <span className="showDatailConnectWalletButton">
                <FontAwesomeIcon icon={faWallet} />
                {currentAccount.slice(0, 4) + "..." + currentAccount.slice(-4)}
              </span>
            )}
          </div>

        </div>
        {currentAccount && (
          <div className="bio">
            イーサリアムウォレットを接続して、メッセージを送ってください
            <span role="img" aria-label="shine">
              ✨
            </span>
          </div>
        )}
        {!currentAccount && (
          <div className="bio">
            <p>イーサリアム BBS✨</p>
            <p>いつまでも残るメッセージをブロックチェーンに刻みます⭐</p>
            <p>ウォレットを接続して書き込みしましょう！</p>
            <p>メッセージ書いた人にいいねやETHを送れるよ💖</p>
          </div>
        )}
        {isLoading && (
          <div className="overlay">
            <div className="loader">Loading...</div>
          </div>
        )}
        {currentAccount && (
          <div className="postContentForm">
            <form onSubmit={handleSubmit(postContent)}>
              {errors.message?.type === 'required' &&
                (
                  <p className="errorMessage" role="alert"><FontAwesomeIcon icon={faCircleExclamation} /> message is required</p>
                )

              }
              <textarea
                {...register("message", { required: "message is required" })}
                aria-invalid={errors.message ? "true" : "false"}
                name="message"
                placeholder="今、なにしてる？"
                type="text"
                id="message"
                value={message}
                cols="45"
                rows="4"
                onChange={(e) => setMessage(e.target.value)}
              />
              <input className="postContentButton" type="submit" disabled={isLoading} />
            </form>
          </div>
        )}
        <div className="postedContentList">
          {currentAccount && allPosts && (
            <div className="selectOrderBy">
              <span onClick={() => getAllPosts(1)}>いいね順</span>
              <span> | </span>
              <span onClick={() => getAllPosts(0)}>投稿日付順</span>
            </div>
          )}

          {currentAccount &&
            allPosts
              .slice(0)
              .reverse()
              .map((post, index) => {
                return (
                  <div
                    key={index}
                    className="postedContent"
                  >
                    <div className="postedAddressAndDate">
                      <span>Posted by {post.address}</span>
                      <span>{post.timestamp.toUTCString()}</span>
                    </div>
                    <div className="postedMessage">{post.message}</div>
                    <div className="postedMessageBottom">
                      <div className="likedPostButton">
                        {post.isLiked && (
                          <span
                            disabled={isLoading}
                            onClick={() => deleteLike(post.likeId.toNumber())}
                            className="deleteLikeButton"
                          >
                            <FontAwesomeIcon icon={faHeart} />
                          </span>
                        )}
                        {!post.isLiked && (
                          <span
                            disabled={isLoading}
                            onClick={() => postLike(post.postId.toNumber())}
                            className="postLikeButton"
                          >
                            <FontAwesomeIcon icon={regularHeart} />
                          </span>

                        )}
                        <span>({post.likeNum.toString()})</span>
                      </div>
                      <div className="sendEthForm">
                        <input
                          type="text"
                          size="10"
                          disabled={isLoading}
                          onChange={(e) => setAmount(e.target.value)}
                        /> eth
                        <input className="sendGiftButton" type="submit" value="ETHを送る" onClick={() => sendGift(post.address)} />
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};
export default App;
